

















import KExpansionPanel from '@/@core/components/expansion-panel/KExpansionPanel.vue';
import KExpansionPanels from '@/@core/components/expansions/k-expansion-panels.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import {
  currencyToNumber,
  useNumberToCurrency
} from '@/@core/helpers/formatter';
import {
  defineComponent,
  onMounted,
  PropType,
  ref
} from '@vue/composition-api';
import SingleMaterialForm from './SingleMaterialForm.vue';

export default defineComponent({
  components: {
    KText,
    SingleMaterialForm,
    KCard,
    KExpansionPanels,
    KExpansionPanel
  },
  name: 'MaterialsForm',
  props: {
    data: {
      type: Object as PropType<any>,
      required: true
    }
  },
  setup(props) {
    const activePanel = ref(null);
    const materialList = [
      {
        subject: 'Bahan Baku',
        value: 'raw-materials'
      },
      {
        subject: 'Bahan Tambahan',
        value: 'additives-materials'
      },
      {
        subject: 'Bahan Penolong',
        value: 'auxiliary-materials'
      }
    ];

    const defaultMaterialData = {
      subject: '',
      needsPerMonth: null,
      pricePerUnit: null,
      totalNeedsPerMonth: null,
      totalNeedsPerYear: null,
      materialOrigin: ''
    };

    const iniMaterial = () => {
      const propsData: any = props.data;
      const hasMaterials = propsData.materials?.length;

      if (hasMaterials) return;

      propsData.materials = [];

      materialList.map((material) => {
        propsData.materials.push({
          value: material.value,
          subject: material.subject,
          data: [{ ...defaultMaterialData }]
        });
      });
    };

    const calculateTotal = (data: any[]) => {
      if (!Array.isArray(data)) return;

      let total = 0;

      data.map((d) => {
        const increament =
          currencyToNumber(d?.totalNeedsPerMonth) ?? 0;

        total += increament;
      });

      return {
        perMonth: useNumberToCurrency(total),
        perYear: useNumberToCurrency(total * 12)
      };
    };

    const getTitle = (value: any) => {
      const subject = value.subject;
      const total = calculateTotal(value.data)?.perMonth;

      return `${subject} --> Rp. ${total}/bulan`;
    };

    onMounted(() => {
      iniMaterial();
    });

    return {
      materialList,
      defaultMaterialData,
      activePanel,
      calculateTotal,
      getTitle
    };
  }
});
