































































import KCurrencyInput from '@/@core/components/input/k-currency-input.vue';
import KArrayInput from '@/@core/components/input/KArrayInput.vue';
import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import KText from '@/@core/components/typography/KText.vue';
import {
  currencyToNumber,
  toCurrency
} from '@/@core/helpers/formatter';
import { useUnitStore } from '@/modules/unit/services/store';
import {
  defineComponent,
  PropType,
  Ref,
  ref
} from '@vue/composition-api';

export default defineComponent({
  components: {
    KText,
    KTextInput,
    KComboboxInput,
    KArrayInput,
    KCurrencyInput
  },
  name: 'SingleMaterialForm',
  props: {
    data: {
      type: Object as PropType<any>,
      required: true
    }
    // index: {
    //     type: Number as PropType<number>,
    //     required: true
    // },
  },
  setup() {
    const reRenderCount: Ref<number> = ref(0);
    const unitStore = useUnitStore();

    const calculateTotalNeeds = (item: any) => {
      const hasValue = item?.needsPerMonth && item?.pricePerUnit;

      if (hasValue) {
        item.totalNeedsPerMonth = toCurrency(
          item.needsPerMonth * currencyToNumber(item.pricePerUnit)
        );
        item.totalNeedsPerYear = toCurrency(
          currencyToNumber(item.totalNeedsPerMonth) * 12
        );
      } else {
        item.totalNeedsPerMonth = 0;
        item.totalNeedsPerYear = 0;
      }

      reRenderCount.value++;
    };

    const materialOriginList = [
      {
        text: 'Lokal',
        value: 'Lokal'
      },
      {
        text: 'Luar Provinsi',
        value: 'Luar Provinsi'
      }
    ];

    return {
      materialOriginList,
      calculateTotalNeeds,
      reRenderCount,
      unitStore
    };
  }
});
